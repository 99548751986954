<template>
  <div class="container">

      <div class="row article-part">
        <div class="col-md-9" v-loading="loading">
          <div class="book-title-header">
            <span class="video-part-title">
              最新文章
            </span>
          </div>
          <div class="article-article-box">

            <div v-show="!isArticles">
              <div class="no-article-text">
                当前分类没有文章
              </div>
            </div>

            <div class="row article-box" v-for="item in articles" :key="item.id">
              <div class="col-md-4">
                 <div class="article-image">
                    <router-link :to="{ path: '/article/details/details', query: { id: item.id } }">
                      <img class="card-img-top"
                        :src="item.image"
                        :item="item.title"
                        :alt="item.title"
                      />
                    </router-link>
                  </div>
              </div>
              <div class="col-md-8">
                <div class="article-title">
                  <router-link :to="{ path: '/article/details/details', query: { id: item.id } }" class="article-title-a">
                    {{ item.title }}
                  </router-link>
                </div>
                <!-- <div class="article-intro">
                  从入口文件/public/index.php可以看出有4个步骤，其中，第3个步骤是最关键的。1.加载Composer生成的预加载文件...
                </div> -->
                <div class="article-footer">
                  <span class="article-icon-box">
                    <span class="article-icon"> <img src="/bootstrap-icons-1.9.1/eye.svg" alt=""></span>
                    <span class="article-icon-text">{{item.view}}</span>
                    <!-- <span class="article-icon"> <img src="/bootstrap-icons-1.9.1/heart.svg" alt=""></span>
                    <span class="article-icon-text">点赞</span>
                    <span class="article-icon"> <img src="/bootstrap-icons-1.9.1/bookmark.svg" alt=""></span>
                    <span class="article-icon-text">收藏</span>
                    <span class="article-icon"> <img src="/bootstrap-icons-1.9.1/share.svg" alt=""></span>
                    <span class="article-icon-text">分享</span> -->
                  </span>

                  <router-link :to="{ path: '/article/details/details', query: { id: item.id } }" class="btn btn-white right">
                    更多
                  </router-link>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-3 article-index-right">
          <!-- <nav class="article-search-right-box">
            <form class="form-inline" style="width: 100%;">
              <input class="form-control article-search-right" type="search" placeholder="关键词" aria-label="Search">
              <button class="btn btn-outline-success my-2 my-sm-0 article-search-right-btn" type="submit">搜索</button>
            </form>
          </nav> -->

          <ul class="list-group">
            <li class="list-group-item active" aria-current="true">分类</li>
            <div v-for="cat in articleCategories" :key="cat.id">
              <!-- <router-link :to="{ path: '/article/category/category', query: { catId: cat.id } }">
                <li class="list-group-item">{{ cat.title }}</li>
              </router-link> -->
              <a :href="'/article/category/category?catId='+cat.id">
                <li class="list-group-item">{{ cat.title }}</li>
              </a>
            </div>
            
          </ul>
        </div>
        
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import { isEmpty } from 'element-plus/es/utils'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      sliders: null,
      articles: null,
      articleCategories: null,
      isArticles: true,
      loading: true,
    }
  },
  created() {
    this.articleList()
    this.articleCategoryList()
  },
  methods: {
    async articleList() {
      if( this.$route.query.catId == undefined) {
        this.$route.query.catId = 0
      }
      const response = await axios.post('http://n.article.creative.com/api/web/articles?catId='+this.$route.query.catId)
      const items = response.data.data.data
      if(isEmpty(response.data.data.data)) {
        this.isArticles = false
      }
      this.articles = items
      this.loading = false
    },
    async articleCategoryList() {     
      const response = await axios.post('http://n.article.creative.com/api/web/article/type/1')
      const items = response.data.data
      this.articleCategories = items
      this.loading = false
    },
    async article(id) {
      this.$router.push({path:`/article/${id}`})
    }
  }
}
</script>
